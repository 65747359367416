<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="400"
        >
            <v-card class="card-padding" >
                <div class="card-form">
                    <div class="line-reg-code-header">
                        <div class="text-header">Line Register Code</div>
                        <div class="pointer" @click="closeModal">X</div>
                    </div>
                    <div class="name-style mb-4">
                        Name: {{fullName}}
                    </div>
                    <qrcode-vue class="d-flex d-flex-justify-center mb-4" :value="value" :size="size" level="H" />
                    <div v-if="isUsed" class="d-flex d-flex-justify-center is-used-text">
                        This code is used
                    </div>
                    <v-text-field v-model="lineRegCode" readonly></v-text-field>
                    <div class="btn-container">
                        <v-btn color="primary" @click="genNewLineCode">Gen Code</v-btn>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
export default {
    name: 'LineCodeGenerate',
    components: {
        QrcodeVue
    },
    props: {
        studentId: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            value: '',
            defaultLineOa: process.env.VUE_APP_LINE_OA_FOR_ADD,
            size: 200,
            dialog: false,
            lineRegCode: '',
            fullName: '',
            isUsed: false
        }
    },

    methods: {
        async getLineCode () {
            const res = await this.axios.get(`/students/${this.studentId}/line-code`)
            if (res && res.data && res.data.status === 'ok') {
                this.lineRegCode = res.data.data.lineRegisCode
                this.value = `${this.defaultLineOa}${this.lineRegCode}`
                this.isUsed = res.data.data.isUsed
                this.fullName = res.data.data.fullName
            }
        },

        async genNewLineCode () {
            const res = await this.axios.put(`/students/${this.studentId}/line-code`)
            if (res && res.data && res.data.status === 'ok') {
                this.lineRegCode = res.data.data.lineRegisCode
                this.value = `${this.defaultLineOa}${this.lineRegCode}`
                this.isUsed = res.data.data.isUsed
                this.fullName = res.data.data.fullName
            }
        },

        closeModal () {
            this.dialog = false
        }
    },

    watch: {
        async dialog (newVal, oldVal) {
            if (newVal) {
                await this.getLineCode()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.line-reg-code-header {
    display: flex;
    justify-content: space-between;
}
.name-style {
    font-size: 24px;
}
.is-used-text {
    font-size: 18px;
    color: rgb(247, 96, 96);
}
</style>
