<template>
    <div>
        <div class="header-page">
            <div class="header-font">
                Students
            </div>
            <div>
                <v-btn color="primary create-btn" @click="createStudent">Create</v-btn>
            </div>
        </div>
        <div class="student-filter-container">
            <div class="filter-search mr-4">
                <v-text-field
                    v-model="filterForm.name"
                    label="Name"
                    prepend-icon="mdi-magnify"
                >
                </v-text-field>
            </div>
            <div class="filter-search mr-4">
                <v-select
                    class="filter-search"
                    v-model="filterForm.branchId"
                    :items="branchList"
                    label="Branch"
                    item-text="key"
                    item-value="value"
                ></v-select>
            </div>
            <div>
                <v-btn
                    @click="getStudentList"
                    color="primary"
                    outlined
                >
                    Search
                </v-btn>
            </div>
        </div>
        <v-data-table
            :headers="headers"
            :items="studentList"
            class="elevation-1"
        >
            <template v-slot:[`item.line`]="{ item }">
                <v-img
                    class="pointer"
                    :src="require('../assets/line-icon.png')"
                    height="24"
                    width="24"
                    @click="openLineCodeModal(item)"
                />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="text-truncate">
                <v-icon
                    small
                    class="mr-2"
                    @click="goToCourseList(item)"
                >
                    mdi-clipboard-list-outline
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="editStudent(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteModal(item)"
                >
                    mdi-delete
                </v-icon>
            </div>
            </template>
        </v-data-table>
        <delete-modal
            ref="deleteModal"
            @delete-success="deleteSuccess"
            :deleteForm="deleteForm"
        ></delete-modal>
        <line-code-generate
            ref="lineCodeModal"
            :studentId="selectStudentId"
        >

        </line-code-generate>
    </div>
</template>

<script>
import DeleteModal from '../components/DeleteModal'
import LineCodeGenerate from '../components/LineCodeGenerate'
export default {
    name: 'Students',
    components: {
        DeleteModal,
        LineCodeGenerate
    },
    data () {
        return {
            studentList: [],
            branchList: [{
                key: 'All',
                value: null
            }],
            headers: [
                { text: 'Name', value: 'fullName' },
                { text: 'Nickname', value: 'nickname' },
                { text: 'Level', value: 'level' },
                { text: 'Branch', value: 'branch' },
                { text: 'Course', value: 'course' },
                { text: 'Line', value: 'line' },
                { text: '', value: 'actions' }
            ],
            deleteForm: {
                header: 'Delete student',
                title: 'You want to delete student ',
                url: ''
            },
            filterForm: {
                name: '',
                branchId: null
            },
            selectStudentId: null,
            mode: ''
        }
    },

    methods: {
        async getStudentList () {
            const url = `students?name=${this.filterForm.name}&branch=${this.filterForm.branchId}`
            const res = await this.axios.get(url)
            if (res && res.data && res.data.status === 'ok') {
                this.studentList = res.data.data
            }
        },

        createStudent () {
            this.$router.push({ path: '/students/create' })
        },

        editStudent (item) {
            this.$router.push({ path: `/students/edit/${item.id}` })
        },

        goToCourseList (item) {
            this.$router.push({ path: `/students/${item.id}/courses` })
        },

        deleteModal (item) {
            this.deleteForm.title = `You want to delete student ${item.fullName} ?`
            this.deleteForm.url = `students/${item.id}`
            this.$refs.deleteModal.dialog = true
        },

        openLineCodeModal (item) {
            this.$refs.lineCodeModal.dialog = true
            this.selectStudentId = item.id
        },

        async deleteSuccess () {
            this.$notify({
                title: 'Congratulations',
                text: 'Delete branch success',
                type: 'success'
            })
            await this.getStudentList()
        },

        async getBranchOptions () {
            const res = await this.axios.get('branches-select')
            if (res && res.data && res.data.status === 'ok') {
                this.branchList = [...this.branchList, ...res.data.data]
                if (localStorage.getItem('branches')) {
                    this.filterForm.branchId = parseInt(localStorage.getItem('branches').split(',')[0])
                }
            }
        }
    },

    async mounted () {
        await this.getBranchOptions()
        await this.getStudentList()
    }
}
</script>

<style lang="scss" scoped>
.student-filter-container {
    display: flex;
    align-items: center;

    .filter-search {
        width: 220px;
    }
}
</style>
